import {
  handleAggregatorResponseWithHeaders,
  isExperimentEnabled,
} from '@wix/communities-blog-client-common';
import {
  EXPERIMENT_OOI_POSTS_WITH_SITE_TIMEZONE,
  EXPERIMENT_RENAME_IN_MODERATION_TO_IN_REVIEW,
} from '@wix/communities-blog-experiments';
import { createPromisifiedAction } from '../../../common/actions-promisifier/create-promisified-action';
import { handleTranslationsResponse } from '../../../common/store/translations/translations-actions';
import { getQueryLocale } from '../../../common/selectors/locale-selectors';
import { getViewedUserSiteMemberId } from '../viewed-user/viewed-user-selectors';
import { getActiveTab } from '../active-tab/active-tab-selectors';
import {
  getPostStatusByMyPostsPageTab,
  MY_POSTS_PAGE_SIZE,
} from '../../constants/my-posts-page';
import setPosts from '../../../common/actions/set-posts';
import { setPostCount } from '../../../common/store/post-count/set-posts-count';
import { getTotalResults } from '../../../common/services/pagination';
import { setIsLoading } from '../../../common/store/is-loading/is-loading-actions';
import timezoneService from '../../../common/services/timezone';
import { getTimezone } from '../../../common/store/basic-params/basic-params-selectors';

export const MY_POSTS_ENTITY = 'my-posts';

export const fetchMyPostsPageInitialData = fetchMyPostsPageRenderModel;
export const fetchMyPostsPageData = (page = 1) =>
  fetchMyPostsPageRenderModel({ page, fields: ['posts'] });
export const fetchMyPostsPageDataPromisified = createPromisifiedAction(
  fetchMyPostsPageData,
  () => null,
  (response) => response.status,
);

function fetchMyPostsPageRenderModel({ page, fields } = {}) {
  return async (dispatch, getState, { aggregatorRequest }) => {
    const state = getState();
    const isPostWithSiteTimezoneEnabled = isExperimentEnabled(
      state,
      EXPERIMENT_OOI_POSTS_WITH_SITE_TIMEZONE,
    );
    const isRenameInModerationToInReviewEnabled = isExperimentEnabled(
      state,
      EXPERIMENT_RENAME_IN_MODERATION_TO_IN_REVIEW,
    );
    const language = getQueryLocale(state);
    const siteMemberId = getViewedUserSiteMemberId(state);
    const activeTab = getActiveTab(state);
    const status = getPostStatusByMyPostsPageTab(
      activeTab,
      isRenameInModerationToInReviewEnabled,
    );
    const timezone = getTimezone(state) || timezoneService.timezone();

    dispatch(setIsLoading(MY_POSTS_ENTITY, undefined, true));

    try {
      const { translations, posts } = await aggregatorRequest(
        `/v1/my-posts-page/render-model?${[
          `siteMemberId=${siteMemberId}`,
          `status=${status}`,
          `postLimit=${MY_POSTS_PAGE_SIZE}`,
          language && `language=${language}`,
          page && `page=${page}`,
          isPostWithSiteTimezoneEnabled && `timezone=${timezone}`,
          fields && `fields=${fields.join(',')}`,
        ]
          .filter(Boolean)
          .join('&')}`,
        { throwOnInvalidJson: true },
      );

      if (translations) {
        await dispatch(handleTranslationsResponse(translations));
      }

      const { body, headers } = await handleAggregatorResponseWithHeaders(
        posts,
      )();
      await dispatch(setPosts(body));
      await dispatch(setPostCount(getTotalResults(headers)));
      dispatch(setIsLoading(MY_POSTS_ENTITY, undefined, false));
    } catch (error) {
      dispatch(setIsLoading(MY_POSTS_ENTITY, undefined, false));
      throw error;
    }
  };
}
